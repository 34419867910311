<template>
  <div class="bg-shop-summary">
    <div class="overviewBottom">
      <div class="box" style="background: rgba(88, 163, 247, 1)">
        <h3>
          <span class="t">{{ salesSummary.totalMoney || "0.00" }}</span>
        </h3>
        <p>今日销售总额</p>
      </div>
      <div class="box" style="background: rgba(254, 192, 61, 1)">
        <h3>
          <span class="b">{{ salesSummary.totalOrderNum || "0" }}</span>
        </h3>
        <p>今日订单总数</p>
      </div>
      <div class="box" style="background: rgba(251, 98, 96, 1)">
        <h3>
          <span>{{ salesSummary.goodsNum || "0" }}</span>
        </h3>
        <p>商品总数量</p>
      </div>
    </div>

    <el-card shadow="hover" class="x-card-title-slot mb-3" :body-style="{padding: 0}">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col header-title">
          企业销售额排行
        </div>
        <div class="col-auto">搜索周期：</div>
        <div class="col-auto mr-2">
          <el-select
            style="width: 100px"
            v-model="dateType1"
            placeholder="请选择类型"
          >
            <el-option label="按月" :value="1"></el-option>
            <el-option label="按年" :value="2"></el-option>
          </el-select>
        </div>
        <div class="col-auto">
          <el-date-picker
            v-if="dateType1 === 1"
            value-format="yyyy-MM"
            v-model="value1"
            type="month"
            placeholder="请选择月份"
            @change="changeYearMonth1"
          >
          </el-date-picker>
          <el-date-picker
            v-else
            v-model="value1"
            value-format="yyyy"
            type="year"
            placeholder="请选择年"
            @change="changeYearMonth1"
          ></el-date-picker>
        </div>
      </div>

      <div id="topPie" :style="{ height: '50vh' }"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3" :body-style="{padding: 0}">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col header-title">
          订单数据统计
        </div>
        <div class="col-auto">搜索周期：</div>
        <div class="col-auto mr-2">
          <el-select
            style="width: 100px"
            v-model="dateType2"
            placeholder="请选择类型"
          >
            <el-option label="按月" :value="1"></el-option>
            <el-option label="按年" :value="2"></el-option>
          </el-select>
        </div>
        <div class="col-auto">
          <el-date-picker
            v-if="dateType2 === 1"
            value-format="yyyy-MM"
            v-model="value2"
            type="month"
            placeholder="请选择月份"
            @change="changeYearMonth2"
          ></el-date-picker>
          <el-date-picker
            v-else
            v-model="value2"
            value-format="yyyy"
            type="year"
            placeholder="请选择年"
            @change="changeYearMonth2"
          ></el-date-picker>
        </div>
      </div>

      <div id="priceList" :style="{ height: '50vh' }"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3" :body-style="{padding: 0}">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col header-title">
          企业用户统计
        </div>
        <div class="col-auto">搜索周期：</div>
        <div class="col-auto mr-2">
          <el-select
            style="width: 100px"
            v-model="dateType3"
            placeholder="请选择类型"
          >
            <el-option label="按月" :value="1"></el-option>
            <el-option label="按年" :value="2"></el-option>
          </el-select>
        </div>
        <div class="col-auto">
          <el-date-picker
            v-if="dateType3 === 1"
            value-format="yyyy-MM"
            v-model="value3"
            type="month"
            placeholder="请选择月份"
            @change="changeYearMonth3"
          ></el-date-picker>
          <el-date-picker
            v-else
            v-model="value3"
            value-format="yyyy"
            type="year"
            placeholder="请选择年"
            @change="changeYearMonth3"
          ></el-date-picker>
        </div>
      </div>

      <div id="priceList1" :style="{ height: '50vh' }"></div>
    </el-card>
  </div>
</template>

<script>
import * as API_Statistics from "@/api/statistics";
import {CategoryPicker} from "@/components";

export default {
  name: "buyAnalysis",
  components: {
    [CategoryPicker.name]: CategoryPicker,
  },
  data() {
    return {
      dateType1: 1,
      dateType2: 1,
      dateType3: 1,
      value1: "",
      value2: "",
      value3: "",
      type: 1,
      salesSummary: {
        totalMoney: "0.00",
        totalOrderNum: "0",
        goodsNum: "0",
      },
      typeList: [
        {
          label: "新增",
          value: 1,
        },
        {
          label: "累计",
          value: 4,
        },
      ],
      tableData: [
        {
          aaaa: "海尔智能马桶盖海尔智能马桶盖海尔智能",
          bbbb: 1,
          cccc: 200,
        },
        {
          aaaa: "海尔智能马桶盖海尔智能马桶盖海尔智能",
          bbbb: 1,
          cccc: 200,
        },
        {
          aaaa: "海尔智能马桶盖海尔智能马桶盖海尔智能",
          bbbb: 1,
          cccc: 200,
        },
        {
          aaaa: "海尔智能马桶盖海尔智能马桶盖海尔智能",
          bbbb: 1,
          cccc: 200,
        },
        {
          aaaa: "海尔智能马桶盖海尔智能马桶盖海尔智能",
          bbbb: 1,
          cccc: 200,
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近7日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近15日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近30日",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      /** 列表loading状态 */
      loading: false,
      importLoading: false,

      daterangeDate: "",

      /** 参数*/
      params1: {
        /** 当前选择的日期类型 */
        cycle_type: "MONTH",

        /** 年份 */
        year: new Date().getFullYear(),

        /** 月份*/
        month: new Date().getMonth() + 1,
      },
      /** 参数*/
      params2: {
        /** 当前选择的日期类型 */
        cycle_type: "MONTH",

        /** 年份 */
        year: new Date().getFullYear(),

        /** 月份*/
        month: new Date().getMonth() + 1,
      },
      /** 参数*/
      params3: {
        /** 当前选择的日期类型 */
        cycle_type: "MONTH",

        /** 年份 */
        year: new Date().getFullYear(),

        /** 月份*/
        month: new Date().getMonth() + 1,
      },

      tableHeight: document.body.clientHeight / 2,
    };
  },
  created() {
    this.getSales();
    this.getEnterpriseSalesMoneySort();
    this.getOrderNum();
    this.getEnterpriseNum();
  },
  mounted() {
    window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.priceListChart1 = this.$echarts.init(
        document.getElementById("priceList1")
      );
      this.priceListChart = this.$echarts.init(
        document.getElementById("priceList")
      );
      this.topPie = this.$echarts.init(document.getElementById("topPie"));
    });
  },
  methods: {
    downZd(row) {
      if (!row) {
        this.importLoading = true;
      }
      // API_account.getSettleOrderList({}).then(response => {
      let tHeaders = [
        nameLabel,
        "订单号",
        "买家会员号",
        "订单金额",
        "快递单号",
        "快递名称",
        "订单日期",
      ];
      let filterVals = [
        nameValue,
        "sn",
        "member_name",
        priceValue,
        "ship_no",
        "logi_name",
        "complete_time",
      ];
      handleDownload(response, tHeaders, filterVals, "财务月汇总账单");
      this.importLoading = false;
      // })
    },
    daterangeDateChange(val) {
      this.params.start_time = val ? val[0] / 1000 : "";
      this.params.end_time = val ? val[1] / 1000 : "";
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2;
      /** 图表刷新 */
      setTimeout(this.priceListChart.resize);
      setTimeout(this.topPie.resize);
      setTimeout(this.priceListChart1.resize);
    },

    /** 改变日期的回调*/
    changeYearMonth1() {
      if (this.dateType1 == 1) {
        var str = this.value1.split("-");
        this.params1.cycle_type = "MONTH";
        this.params1.year = Number(str[0]);
        this.params1.month = Number(str[1]);
      } else {
        this.params1.cycle_type = "YEAR";
        this.params1.year = Number(this.value1);
        this.$delete(this.params1, "month");
      }
      this.getEnterpriseSalesMoneySort();
    },
    /** 改变日期的回调*/
    changeYearMonth2() {
      if (this.dateType2 == 1) {
        var str = this.value2.split("-");
        this.params2.cycle_type = "MONTH";
        this.params2.year = Number(str[0]);
        this.params2.month = Number(str[1]);
      } else {
        this.params2.cycle_type = "YEAR";
        this.params2.year = Number(this.value2);
        this.$delete(this.params2, "month");
      }
      this.getOrderNum();
    },
    /** 改变日期的回调*/
    changeYearMonth3() {
      if (this.dateType3 == 1) {
        var str = this.value3.split("-");
        this.params3.cycle_type = "MONTH";
        this.params3.year = Number(str[0]);
        this.params3.month = Number(str[1]);
      } else {
        this.params3.cycle_type = "YEAR";
        this.params3.year = Number(this.value3);
        this.$delete(this.params3, "month");
      }
      this.getEnterpriseNum();
    },
    /** 价格区间 */
    changePriceRange(obj) {
      this.params = {
        ...this.params,
        ranges: obj,
      };
    },

    /** 执行搜索 */
    handleSearch() {
      /** 处理区域数据 */
      let ranges = [];
      if (this.params.ranges && this.params.ranges.length) {
        this.params.ranges.forEach((key, index) => {
          if (index === 0) ranges.push(key[0]);
          ranges.push(key[1]);
        });
        this.params.ranges = ranges;
      }
      this.getEnterpriseSalesMoneySort();
    },
    getSales() {
      API_Statistics.getSales().then((res) => {
        this.salesSummary = res;
      });
    },
    getEnterpriseSalesMoneySort() {
      API_Statistics.getEenterpriseSalesMoneySort(this.params1).then(
        (response) => {
          this.topPie.setOption({
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgb(88, 163, 247)",
            },
            grid: {
              y: "80px",
              //   x2: "60px",
              y2: "30px",
              containLabel: true,
            },
            legend: {
              show: true,
              data: [
                {
                  name: response.series.name,
                },
              ],
              top: "10px",
            },
            xAxis: {
              name: "名次",
              type: "category",
              boundaryGap: false,
              data: response.xAxis,
              // axisLabel: {
              //   interval: 0,
              //   rotate: 15,
              // },
              axisLabel: {
                rotate: 15,
                interval: 0,//横轴信息全部显示
                formatter: function (value) {
                  return value.length > 8 ? value.substring(0, 8) + '...' : value;
                  var ret = ""; //拼接加\n返回的类目项
                  var maxLength = 1; //每项显示文字个数
                  var valLength = value.length; //X轴类目项的文字个数
                  var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                  console.log(rowN, 'rowN')
                  if (rowN > 1) {
                    //如果类目项的文字大于3,
                    for (var i = 0; i < rowN; i++) {
                      var temp = ""; //每次截取的字符串
                      var start = i * maxLength; //开始截取的位置
                      var end = start + maxLength; //结束截取的位置
                      //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                      temp = value.substring(start, end) + "\n";
                      ret += temp; //凭借最终的字符串
                    }
                    return ret;
                  } else {
                    return value;
                  }
                },
              },
            },
            yAxis: {
              name: "价格（元）",
              type: "value",
              axisLabel: {
                formatter: "{value} ",
              },
            },
            series: [
              {
                type: "bar",
                data: response.series.data,
                name: response.series.name,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 2,
                      color: "#1a43a9",
                    },
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#c2bbfe",
                      },
                      {
                        offset: 1,
                        color: "#d3edfb",
                      },
                    ]),
                  },
                },
                barWidth: 20,
                areaStyle: {},
                smooth: true,
              },
            ],
          });
        }
      );
    },
    getOrderNum() {
      API_Statistics.getOrderNum(this.params2).then((response) => {
        // response.series[0].data = [10,30,40,50,20,30,10,40]
        // response.series[1].data = [20,30,10,40,10,30,40,50]
        response.series[1].name = response.series[1].name.replace(/退款/g, '申请售后')
        this.priceListChart.setOption({
          tooltip: {
            trigger: "axis",
          },
          grid: {
            x: "50px",
            y: "80px",
            x2: "60px",
            y2: "30px",
          },
          legend: {
            show: true,
            data: [
              {
                name: response.series[0].name,
              },
              {
                name: response.series[1].name,
              },
            ],
            top: "10px",
          },
          xAxis: {
            name: `${this.params2.cycle_type !== "MONTH" ? "月份" : "日期"}`,
            type: "category",
            boundaryGap: false,
            data: response.xAxis,
          },
          yAxis: {
            name: "订单数",
            type: "value",
            axisLabel: {
              formatter: "{value} ",
            },
          },
          series: [
            {
              type: "line",
              data: response.series[0].data,
              name: response.series[0].name,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#1a43a9",
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#c2bbfe",
                    },
                    {
                      offset: 1,
                      color: "#d3edfb",
                    },
                  ]),
                },
              },
              areaStyle: {
                normal: {},
              },
              symbolSize: 0,
              barWidth: 50,
              smooth: true,
            },
            {
              type: "line",
              name: response.series[1].name,
              data: response.series[1].data,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#4fa736",
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#acd9a9",
                    },
                    {
                      offset: 1,
                      color: "#d0eccd",
                    },
                  ]),
                },
              },
              areaStyle: {
                normal: {},
              },
              symbolSize: 0,
              barWidth: 50,
              smooth: true,
            },
          ],
        });
      });
    },
    getEnterpriseNum() {
      API_Statistics.getEnterpriseNum(this.params3).then((response) => {
        this.priceListChart1.setOption({
          tooltip: {
            trigger: "axis",
          },
          grid: {
            x: "50px",
            y: "80px",
            x2: "60px",
            y2: "30px",
          },
          legend: {
            show: true,
            data: [
              {
                name: response.series[0].name,
              },
              {
                name: response.series[1].name,
              },
            ],
            top: "10px",
          },
          xAxis: {
            name: `${this.params3.cycle_type !== "MONTH" ? "月份" : "日期"}`,
            type: "category",
            boundaryGap: false,
            data: response.xAxis,
          },
          yAxis: {
            name: "用户数",
            type: "value",
            axisLabel: {
              formatter: "{value} ",
            },
          },
          series: [
            {
              type: "line",
              data: response.series[0].data,
              name: response.series[0].name,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#1a43a9",
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#c2bbfe",
                    },
                    {
                      offset: 1,
                      color: "#d3edfb",
                    },
                  ]),
                },
              },
              areaStyle: {
                normal: {},
              },
              symbolSize: 0,
              barWidth: 50,
              smooth: true,
            },
            {
              type: "line",
              name: response.series[1].name,
              data: response.series[1].data,
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 2,
                    color: "#4fa736",
                  },
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#acd9a9",
                    },
                    {
                      offset: 1,
                      color: "#d0eccd",
                    },
                  ]),
                },
              },
              areaStyle: {
                normal: {},
              },
              symbolSize: 0,
              barWidth: 50,
              smooth: true,
            },
          ],
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.overviewBottom {
  overflow: hidden;
  margin-bottom: 10px;
}

.overviewBottom .box {
  float: left;
  background: #fff;
  padding: 40px 30px;
  margin-right: 2%;
  width: 32%;
  border-radius: 5px;
}

.overviewBottom .box img {
  display: block;
  margin: auto;
  height: 50px;
}

.overviewBottom .box h3 {
  margin: 0;
  line-height: 40px;
  font-weight: normal;
}

.overviewBottom .box h3 span {
  font-size: 36px;
  color: #fff;
}

.overviewBottom .box p {
  font-size: 18px;
  color: #fff;
  margin: 0;
  margin-top: 10px;
}

.overviewBottom .box:last-child {
  margin-right: 0;
}
</style>
